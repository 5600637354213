import { render, staticRenderFns } from "./Cart.vue?vue&type=template&id=03821696"
import script from "./Cart.vue?vue&type=script&lang=js"
export * from "./Cart.vue?vue&type=script&lang=js"
import style0 from "./Cart.vue?vue&type=style&index=0&id=03821696&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QAvatar,QBadge,QCheckbox});
